
import * as urldata from '../assets/config/config.json';
import * as CryptoJS from 'crypto-js';
const webApiBaseUrl: any = urldata.default.webApiBaseUrl;

const key = CryptoJS.enc.Utf8.parse('7061737323313233');
const iv = CryptoJS.enc.Utf8.parse('7061737323313233');

export class AppConfig {
  public readonly DocumentImageUrl = urldata.default.DocumentImageUrl;
// Public Messages
public readonly ErrorMSG = 'The system is experiencing technical difficulties please ' +
  'refresh the page if the error persists then please login again';
// SIMS SSO USER LOGIN
public readonly userverification = 'api/Login/UserVerfication';
public readonly ForgotPasswordApi = 'api/Master/ForgotPassword';
public readonly SaveForgetPwdApi = 'api/Master/SaveForgetPwd';
public readonly VerifyOTPApi = 'api/Master/VerifyOTP';

// END
// Login and Product Services
public readonly apiUrl = webApiBaseUrl;
public readonly LoginApi = 'api/Master/Login';
public readonly GetNotificationApi = 'api/Master/GetNotification';

// ENCRYPT AND DECRYPT
  encrypt(text: any) {
    return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
  }
  decrypt(text: any) {
    return CryptoJS.AES.decrypt(text, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }
// END
}