import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from '../../services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../app.component';
import { AppConfig } from '../../app.config';
import { LoaderService } from '../../shared/loader/loader.service';
import { NotificationService } from '../../services/notification.service';
import { Employee } from '../../models/master';

@Component({
  selector: 'otp',
  templateUrl: './otp.component.html'
})
export class OtpComponent implements OnInit {
  model: any = {};
  title = ':: Login ::';
  returnUrl: string;
  currentUser: Employee;
  ipaddress: string;
  emailaddress: string = '';
  CurrentUser: any;
  UserId: number = 0;
  otp: string = '';
  EnCode: string = '';
  constructor(private notifyService: NotificationService, private service: UserService,
    private appnotification: AppComponent, 
    private loaderService: LoaderService, private config: AppConfig) {
    appnotification.master = false;
    const userinfo = localStorage.getItem('currentUser');
    const decryptuserinfo: any = this.config.decrypt(userinfo);
    this.CurrentUser = JSON.parse(decryptuserinfo);
    this.UserId = this.CurrentUser.empId;
    this.EnCode = this.CurrentUser.encode;
  }

  ngOnInit() {

  }

  VerifyOTP() {
    //window.location.href = 'home';
    this.loaderService.show();
    this.service.getbyparameter(this.config.VerifyOTPApi, 
      { empId: this.UserId, otp: this.otp, encode: this.EnCode}).subscribe(objectlist => {
        debugger;
      if(objectlist.message === 'Success'){
        window.location.href = 'home';
      }
      else{
        this.notifyService.showWarning("Please enter corret OTP", '');
        this.otp = '';
      }
      this.loaderService.hide();
    }, error => { this.loaderService.hide(); });
  }
}
