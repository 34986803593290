import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppConfig } from '../app.config';


@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient, private config: AppConfig) { }

    login(username: string, password: string) {
        return this.http.post(this.config.apiUrl + this.config.LoginApi, { Email: username, Pass: password })
            .map(response => {
                // login successful if there's a jwt token in the response
                const user: any = response;
                if (user) {
                    const encryptuserinfo: any = this.config.encrypt(JSON.stringify(user));
                    localStorage.setItem('currentUser', encryptuserinfo);
                }
            });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.clear();
    }
}