import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as XLSX from 'ts-xlsx';
import { read, IWorkBook } from 'ts-xlsx';
import { ParsingOptions, utils, write, WorkBook } from 'xlsx';
import { saveAs } from 'file-saver';

@Injectable()
export class ExportExcelService {
    constructor() { }
    ExportExcel(ent, name) {
        const ws_name = 'Sheet';
        const wb: WorkBook = { SheetNames: [], Sheets: {} };
        const ws: any = utils.json_to_sheet(ent);
        wb.SheetNames.push(ws_name);
        wb.Sheets[ws_name] = ws;
        const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i) {
                view[i] = s.charCodeAt(i) & 0xFF;
            };
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), name);
    }
}