import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ngx-pages',
  templateUrl: './pages.component.html',
})
export class PagesComponent {
  breadcrumbs = [];
  constructor(private route: Router) {
  }
  ngOnInit() {
    
  }
}
