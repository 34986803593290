import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../app.component';
import { AppConfig } from '../../app.config';
import { LoaderService } from '../../shared/loader/loader.service';
import { NotificationService } from '../../services/notification.service';
import { Employee } from '../../models/master';
@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  model: any = {};
  title = ':: Login ::';
  returnUrl: string;
  currentUser: Employee;
  ipaddress: string;
  curyear: any;

  constructor(private notifyService: NotificationService,
    private route: ActivatedRoute, private appnotification: AppComponent, 
    private loaderService: LoaderService, private config: AppConfig, 
    private authenticationService: AuthenticationService) { appnotification.master = false; }

  ngOnInit() {
    var todayDate = new Date();
    this.curyear = todayDate.getFullYear(); 
    this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'home';
    // get ip
    this.ipaddress = '0.0.0.0';
  }

  Login() {
    this.loaderService.show();
    this.authenticationService.login(this.model.username, this.model.password)
      .subscribe(
        data => {
          if (localStorage.getItem('currentUser')) {
            const userinfo = localStorage.getItem('currentUser');
            const decryptuserinfo: any = this.config.decrypt(userinfo);
            this.currentUser = JSON.parse(decryptuserinfo);
            if (this.currentUser) {
              this.loaderService.hide();
              const userstatus = this.currentUser.statusId;
              if (userstatus === null) {
                window.location.href = this.returnUrl;
              } else {
                window.location.href = 'otp';
              }
            } else {
              this.notifyService.showWarning('Username is incorrect', '');
              this.loaderService.hide();
            }
          } else {
            this.notifyService.showWarning('Username is incorrect', '');
            this.loaderService.hide();
          }
        },
        error => {
          this.loaderService.hide();
          this.notifyService.showError(this.config.ErrorMSG, '');
          console.log('Function- login;Error -' + error);
        });
  }
}
