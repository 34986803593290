import { Component, AfterViewInit, Compiler } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import * as urldata from '../assets/config/config.json';
import { AppConfig } from './app.config';
import { UserService } from './services/user.service';
import { MatDialog } from '@angular/material';
import { MasterAppConfig } from './views/pages/config/master.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  componentloading;
  menu: any;
  ActiveMenuid: number;
  master = true;
  loading = false;
  title = 'app';
  userid: any;
  roleid: any;
  Users: any;
  username: any;
  email: any;
  sso: string;
  notificationcount: any = 0;
  notificationlist: any;
  workforname: string = '';
  UserImage: string = '';
  NotifyList: any;
  constructor(private service: UserService, private route: Router, 
    private appconfig: AppConfig, public dialog: MatDialog, private appcompiler: Compiler) {
    this.componentloading = true;
    const userinfo = localStorage.getItem('currentUser');
    if (userinfo) {
      const decryptuserinfo: any = this.appconfig.decrypt(userinfo);
      this.Users = JSON.parse(decryptuserinfo);
      if (this.Users) {
        this.username = this.Users.fname + ' ' + this.Users.lname;
        this.email = this.Users.email;
        this.master = true;
        this.userid = this.Users.empId;
        this.UserImage = this.appconfig.DocumentImageUrl + 'Document/Master/' + this.Users.address2;
      }
    }
    this.sso = this.getCookie('Labalaya');
  }

  ngOnInit(): void {
    //this.GetNotifyList();
  }

  ngAfterViewInit() {
    this.route.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.appcompiler.clearCache();
          this.componentloading = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.componentloading = false;
        }
      });
  }

  GetNotifyList() {
    this.service.getAll(this.appconfig.GetNotificationApi).subscribe(objectList => {
      this.NotifyList = objectList;
    }, error => {
      this.NotifyList = null;
      console.log('Function- GetEnquiryListApi;Error -' + JSON.stringify(error));
    });
  }

  backsso() {
    localStorage.setItem('currentUser', null);
    localStorage.removeItem('currentUser');
    let SSOIN: any;
    if (this.roleid === 1) {
      SSOIN = urldata.default.SSOINADMIN;
    } else { SSOIN = urldata.default.SSOINSELLER; }
    window.location.href = SSOIN; // 'https://sims.rajasthan.gov.in/rajsso.html';
  }

  logout() {
    localStorage.removeItem('currentUser');
    // Delete SSOCOOKIES
    this.setCookie('Labalaya', '', 1);
    // window.location.href = 'https://ebazaar.rajasthan.gov.in/ebzv2/';
    // window.location.href = 'http://ebazzartest.rajasthan.gov.in/ebzv2/';
    window.location.href = '/';
  }

  routetolink(page) {
    if (page !== '') {
      this.route.navigate([page]);
    }
  }
  public setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }
  public getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
}
