import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from '../../services/index';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppComponent } from '../../app.component';
import { AppConfig } from '../../app.config';
import { LoaderService } from '../../shared/loader/loader.service';
import { NotificationService } from '../../services/notification.service';
import { Employee } from '../../models/master';
@Component({
  selector: 'resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetPasswordComponent implements OnInit {
  model: any = {};
  title = ':: Reset Password ::';
  returnUrl: string;
  currentUser: Employee;
  ipaddress: string;
  emailaddress: string = '';
  password: string = '';
  confirmpassword: string = '';
  encode: string = '';
  constructor(private notifyService: NotificationService, private service: UserService,
    private route: ActivatedRoute, private appnotification: AppComponent, 
    private loaderService: LoaderService,
    private router: Router, private config: AppConfig, 
    private activeRout: ActivatedRoute) { appnotification.master = false; }

  ngOnInit() {
    this.activeRout.params.subscribe((params: Params) => {
      if (params['encode']) {
        this.encode = params['encode'];
      }
    });
  }

  SaveForgetPwd(): void {
    this.loaderService.show();
    this.service.getbyparameter(this.config.SaveForgetPwdApi, {
      encode: this.encode, email: '', pwd: this.confirmpassword, flag: 1
    }).subscribe(response => {
      const msg = response.message;
      this.loaderService.hide();
      if(msg === "Success"){
      this.notifyService.showSuccess("Password changed successfully, Try with new password", '');
      this.router.navigate(['../login']);
      }
      else{
        this.notifyService.showWarning("Try to regenerate the new link for change password", '');
      }
    },
      error => {
        this.notifyService.showError(error.message, '');
        this.loaderService.hide();
      }
    );
  }
}
