import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../app/views/login/login.component';
import { PagesComponent } from '../app/views/pages/pages.component';
import { ForgetComponent } from '../app/views/login/forget.component';
import { ResetPasswordComponent } from '../app/views/login/resetpassword.component';
import { OtpComponent } from './views/login/otp.component';

const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'login', component: LoginComponent},
    { path: 'forget', component: ForgetComponent},
    { path: 'otp', component: OtpComponent},
    { path: 'resetpassword', component: ResetPasswordComponent},
    { path: 'resetpassword/:encode', component: ResetPasswordComponent},
    { path: '', component: PagesComponent, loadChildren: () => import('../app/views/pages/pages.module').then(m => m.PagesModule)},
 ];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
