import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { LoaderService } from './loader.service';
import { LoaderState, LoaderMSG } from './loader';

@Component({
    selector: 'angular-loader',
    templateUrl: 'loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {
    show = false;

    private subscription: Subscription;
    private subscriptionmsg: Subscription;

    constructor(
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
                this.show = state.show;
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscriptionmsg.unsubscribe();
    }
}