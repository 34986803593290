import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './views/login/login.component';
import { PagesComponent } from '../app/views/pages/pages.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationService, UserService, ConfirmationDialogsService,
  ExportExcelService } from './services/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppConfig } from './app.config';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule, MatAutocompleteModule, MatInputModule } from '@angular/material';
import { ConfirmationDialogComponent, BreadcrumbComponent } from './shared/index';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './shared/loader/loader.service';
import { ForgetComponent } from './views/login/forget.component';
import { ResetPasswordComponent } from './views/login/resetpassword.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { OtpComponent } from './views/login/otp.component';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent, LoginComponent, PagesComponent, ConfirmationDialogComponent, BreadcrumbComponent,
    LoaderComponent, ForgetComponent, ResetPasswordComponent, OtpComponent
  ],
  entryComponents: [ConfirmationDialogComponent],
  imports: [HttpClientModule, ToastrModule.forRoot(), MatDialogModule,
    MatAutocompleteModule, MatInputModule, BrowserAnimationsModule,
    BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule,
    CKEditorModule
  ],
  providers: [
    AppConfig, LoaderService,
    AuthenticationService, ConfirmationDialogsService,
    UserService, ExportExcelService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
