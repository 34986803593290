import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/index';
import { AppComponent } from '../../app.component';
import { AppConfig } from '../../app.config';
import { LoaderService } from '../../shared/loader/loader.service';
import { NotificationService } from '../../services/notification.service';
import { Employee } from '../../models/master';

@Component({
  selector: 'forget',
  templateUrl: './forget.component.html'
})
export class ForgetComponent implements OnInit {
  model: any = {};
  title = ':: Login ::';
  returnUrl: string;
  currentUser: Employee;
  ipaddress: string;
  emailaddress: string = '';
  constructor(private notifyService: NotificationService, private service: UserService,
    private appnotification: AppComponent, private loaderService: LoaderService,
    private config: AppConfig) { appnotification.master = false; }

  ngOnInit() {

  }

  ForgotPassword() {
    this.loaderService.show();
    this.service.getById(this.config.ForgotPasswordApi, this.emailaddress).subscribe(objectlist => {
      this.model = objectlist;
      this.notifyService.showSuccess("We have sent a link to your email address for change the password", '');
      this.loaderService.hide();
    }, error => { this.loaderService.hide(); });
  }
}
